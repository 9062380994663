var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "item" }, [
    _c("div", { staticClass: "left" }, [
      _c(
        "div",
        { staticClass: "image" },
        [
          _c("image-view", {
            staticClass: "image-item",
            attrs: {
              src: _vm.imageKey(_vm.novel),
              placeholder: "/img/placeholders/novel.png",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [_vm._t("container")], 2),
    ]),
    _c("div", { staticClass: "right" }, [_vm._t("right")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }